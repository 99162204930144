import { createWeb3Modal, useWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
const projectId = "4b18cb40af85005cce1e7e62c82f15c9";
import { base } from "wagmi/chains";
import {http} from "viem";

const chains = [
    base,
] as const;

const metadata = {
  name: "Swisstronik Hegel NFT Mint",
  description: "Swisstronik Hegel NFT Mint",
  url: "https://nft.swisstronik.com", // origin must match your domain & subdomain
  icons: ["https://swisstronik.com/assets/favicons/favicon-192.png"],
};

export const wagmiConfig = defaultWagmiConfig({
  projectId,
  chains,
  metadata,
  transports: {
    [base.id]: http('https://site1.moralis-nodes.com/base/dda0f0a607704abaa59bf4bc40225d0d'),
  },
});

createWeb3Modal({
  wagmiConfig,
  projectId,
  enableOnramp: true,
});
