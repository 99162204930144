// "0x1EDdf4340a3A7d09b26C00b817B56e690CF32A7E";
// - 20.08.2024
// "0xd95f5145074a5049b91135b042c96826abbf0a07";
// - 21.08.2024
//"0x384ab71704c916e8b5f1edeeae7974f87aabfee3";
// - 22.08.2024
//"0xC8Fb579aF74c84Cd43A127cb6f41312Ed110b233";
// - 23.08.2024
// 0x42922F5b9533e5965d285CBa05645789BCF1FA52
// - 27.08.2024
// 0x1eD9eE2d5A90EF7948277604983fdf0fF9b61BfB
// - 28.08.2024

//0x054d01149FD24783f3Eda64a8FE29a822f297946
//0x29Add6E528Ddb8Aec972Ec7404d14f848Eb055bf
// - 29.08.2024

 //0xF2ee26640D9679CB8F6aeBe84Ff678f27EA30279
 //0x03365AEbC52F638932ae978AcaC86cd46CAC1aA2
 //0x61de4adC22C198F80Ff5932661B2FA083e8aEAa9
// - 30.08.2024

// 0x19b5d84e8c335220d21fdff7e7e26525c556f801;
// - 02.09.2024
export const nftAddress = "0xC559a56BD9c2C789AA0e464D54f611436A109E5e";

export const getNFTMeta = async (url: any) => {
  return await fetch(url)
    .then(async (res: any) => {
      if (res.ok) {
        return await res?.json();
      } else {
        throw Error("Invalid");
      }
    })
    .catch((err: any) => {
      return err;
    });
};

