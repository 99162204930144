import mainLogo from "../assets/picture/logo.svg";

const Logo = () => {
  return (
    <div className="flex flex-row md:flex-row sm:items-center gap-x-[16px] md:gap-x-[24px] ">
      <div>
        <img className='xs:w-6 md:w-9' src={mainLogo} alt="Hegel NFT Mint"/>
      </div>
      <div className="bg-neutral-200 w-[2px] h-[36px]"></div>
      <div className="flex flex-col">
        <span className="font-inter font-semibold md:text-xs xs:text-xs">
        Hegel NFT Mint
      </span>
        <span className="font-inter font-semibold md:text-2xl xs:text-xl">
        Rarity Check
      </span>
      </div>


    </div>
  );
};

export default Logo;
