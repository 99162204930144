
const FooterWordLogo = () => {
  const footerLogoHeadingStyles = `xs:text-[44.22px] md:text-[166.59px] font-normal fnt-83-166 font-lexend text-accent-dark
  flex md:justify-center items-baseline`
  const footerLogoWordStyles = `transition-all duration-150 hover:text-primary reletive hover:-translate-y-1.5`
  return(

  <div>
      <h2 className={`${footerLogoHeadingStyles}`}>
        <span className={`${footerLogoWordStyles}`}>S</span>
        <span className={`${footerLogoWordStyles}`}>W</span>
        <span className={`${footerLogoWordStyles}`}>I</span>
        <span className={`${footerLogoWordStyles}`}>S</span>
        <span className={`${footerLogoWordStyles}`}>S</span>
        <span className={`${footerLogoWordStyles}`}>T</span>
        <span className={`${footerLogoWordStyles}`}>R</span>
        <span className={`${footerLogoWordStyles}`}>O</span>
        <span className={`${footerLogoWordStyles}`}>N</span>
        <span className={`${footerLogoWordStyles}`}>I</span>
        <span className={`${footerLogoWordStyles}`}>K</span>
      </h2>
  </div>

)}
export default FooterWordLogo