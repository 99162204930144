import { useState, useMemo, useEffect } from "react";
import Footer from "./components/Footer";
import CountDown from "./pages/CountDown";
import { useAccount, WagmiProvider, useReadContract } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import Rounds from "./pages/Round";
import "react-toastify/dist/ReactToastify.css";
import { wagmiConfig } from './utils/walletConnectConfig';
import { abi } from './utils/abi';
import { nftAddress } from './utils/utils';
import moment from 'moment';
import RarityChecker from "./pages/RarityChecker";
const queryClient = new QueryClient();


function App(): JSX.Element {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <div className="flex flex-col">
          <RarityChecker />
          <Footer />
        </div>
        <ToastContainer className={`md:min-w-[60vw]`} />
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;



const CountDownComponent = () => {
  // @ts-ignore
  const { data: roundTimes }: any = useReadContract({
    abi: abi,
    address: nftAddress,
    functionName: "getRoundTimes",
    args: [],
  } as const);

  /*
  * NOTE: Get GTD start time
  * and set how much time left to start
  */
  const startGTDTime = moment.unix(Number(roundTimes?.[0]?.toString()));
  const [countDownStartDate, setGTDStartTime] = useState<Date>(startGTDTime.toDate());

  useEffect(() => {
    if(roundTimes) {
      setGTDStartTime(startGTDTime.toDate());
    }
  }, [roundTimes]);

  return (
    <div className='min-h-screen'>
      {roundTimes && (
        <CountDown key={123} endDate={countDownStartDate} />
      )}
    </div>
  );
}
