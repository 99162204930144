import { LinkProps } from '../utils/types'

interface LinksArrayProps {
  title: string
  links: LinkProps[]
}

export const linksArray: LinksArrayProps[] = [
  {
    title: "Website",
    links: [
      {
        name: "Home",
        url: "/",
        target: "_self",
      },
      {
        name: "For Developers",
        url: "https://www.swisstronik.com/dev-portal",
        target: "_blank",
      },
      {
        name: "For Validators",
        url: "https://www.swisstronik.com/validators",
        target: "_blank",
      },
      {
        name: "For Ambassadors",
        url: "https://www.swisstronik.com/ambassador",
        target: "_blank",
      },
      {
        name: "Grants",
        url: "https://www.swisstronik.com/grants-program",
        target: "_blank",
      },
      // { name: 'Web3 Expedition', url: '/web3expedition', target: '_blank' },
      {
        name: "Testnet 2.0",
        url: "https://www.swisstronik.com/testnet2",
        target: "_blank",
      },
      {
        name: "F.A.Q.",
        url: "https://www.swisstronik.com/faq",
        target: "_blank",
      },
      {
        name: "Pre-Offering",
        url: "https://www.swisstronik.com/pre-offering",
        target: "_blank",
      },
    ],
  },
  {
    title: "Developer resources",
    links: [
      {
        name: "Bug Bounty Program",
        url: "https://www.swisstronik.com/bug-bounty",
        target: "_blank",
      },
      {
        name: "Documentation",
        url: "https://swisstronik.gitbook.io/swisstronik-docs/ ",
        target: "_blank",
      },
      {
        name: "Cosmos block explorer",
        url: "https://explorer-cosmos.testnet.swisstronik.com/swisstronik",
        target: "_blank",
      },
      {
        name: "EVM block explorer",
        url: "https://explorer-evm.testnet.swisstronik.com/",
        target: "_blank",
      },
      {
        name: "Issuer Registry",
        url: "https://www.swisstronik.com/issuer-registry",
        target: "_blank",
      },
      {
        name: "Faucet",
        url: "https://faucet.testnet.swisstronik.com/",
        target: "_blank",
      },
      { name: "GitHub", url: "https://github.com/SigmaGmbH", target: "_blank" },
    ],
  },
  {
    title: "Apps",
    links: [
      {
        name: "App Store",
        url: "https://apps.apple.com/ch/app/swisstronik/id1619756302?l=en",
        target: "_blank",
      },
      {
        name: "Google Play",
        url: "https://play.google.com/store/apps/details?id=com.sigma.wallet&pli=1",
        target: "_blank",
      },
      {
        name: "Web app",
        url: "https://link.swisstronik.com/196736",
        target: "_blank",
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        name: "Whitepaper",
        url: "https://panel.swisstronik.com/media/Whitepaper.pdf",
        target: "_blank",
      },
      {
        name: "Documents",
        url: "https://www.swisstronik.com/documents",
        target: "_blank",
      },
      // { name: 'App Privacy Policy', url: '/privacy', target: '_blank' },
      // { name: 'App Terms of Use', url: '/terms', target: '_blank' },
      // // { name: 'Early Bird Program Announcement', url: '/early-bird-announcement', target: '_blank' },
      // {
      //   name: 'Early Bird Program Terms and Conditions',
      //   url: '/early-bird-terms',
      //   target: '_blank',
      // },
      // {
      //   name: 'Bug Bounty Program Terms and Conditions',
      //   url: '/bug-bounty-terms',
      //   target: '_blank',
      // },
      // {
      //   name: 'Zealy Quests Program Terms and Conditions',
      //   url: '/zealy-terms',
      //   target: '_blank',
      // },
      // { name: 'TaskOn Quests - Terms and Conditions', url: '/taskon-terms', target: '_blank' },
      // { name: 'Swisstronik Code Challenges - Terms and Conditions', url: '/code-challenges-terms', target: '_blank' },
    ],
  },
];
export default linksArray
