import Icon from "../components/Icon";
import { useForm } from "react-hook-form";
import {
  EmailPattern,
  getNotifedIcon,
  onSubmit,
  SuccessfulMessage,
  UnsuccessfulMessage,
} from "../utils/api";
import linksArray from "../utils/links";
import { useEffect, useState } from "react";
import { IconType } from "../components/Icon/types";
import SocialLinks from "../assets/brands/SocialLinks";
import FooterWordLogo from "../assets/helpers/FooterWordLogo";
import Divider from "./Divider";

interface IFormInput {
  email: string;
}

const LandingFooter = () => {
  const [emailInput, setEmailInput] = useState<string>("");
  const [loading, setLoadingState] = useState<boolean>(false);
  const [notifIcon, setNotifIcon] = useState<IconType>("arrow");
  const [currentRoute, setCurrentRoute] = useState(window.location.pathname);
  const [subscribeStatus, setSubscribeStatus] = useState<boolean | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ mode: "onChange", delayError: 600 });

  const onSubmitForm = async (e: IFormInput) => {
    setLoadingState(true);
    const res = await onSubmit(e);
    if (res) {
      setSubscribeStatus(true);
      setEmailInput(SuccessfulMessage);
    } else {
      setSubscribeStatus(false);
      setEmailInput(UnsuccessfulMessage);
    }
    setLoadingState(false);
  };

  const onFocusForm = () => {
    setSubscribeStatus(null);
    setNotifIcon("arrow");
    setEmailInput("");
  };

  useEffect(() => {
    setCurrentRoute(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    const currentIcon = getNotifedIcon(loading, subscribeStatus);
    setNotifIcon(currentIcon || "arrow");
  }, [subscribeStatus, loading]);

  if (currentRoute.includes("employee")) {
    return <></>;
  } else {
    return (
      <>
        <div className="footer_container flex w-full lg:mt-[64px] flex-col lg:pb-18 xs:pb-[54px] bg-footer-landing-bg">
          <footer
            id="landingAppFooter"
            className="flex flex-col gap-12 self-center xs:max-lg:px-6 xs:max-lg:py-12 text-sm leading-snug text-white lg:gap-16 lg:px-16 lg:py-26"
          >
            <div className="flex flex-col justify-between gap-16 md:flex-row">
              <div className="flex w-full flex-col gap-4 sm:w-80">
                <p className="text-base font-medium leading-snug text-neutral-400">
                  Subscribe for fresh content & product updates
                </p>
                <form className="email-form redesigned">
                  <input
                    className={`input ${
                      errors?.email || subscribeStatus === false ? "error" : ""
                    } 
          !bg-secondary !placeholder-neutral-400`}
                    placeholder="Your email"
                    value={emailInput}
                    onFocus={onFocusForm}
                    {...register("email", {
                      required: true,
                      onChange: (e) => setEmailInput(e.target.value),
                      validate: (email: string) =>
                        EmailPattern.test(email) ? true : "Invalid email",
                    })}
                  />
                  <button
                    className="button"
                    onClick={handleSubmit(onSubmitForm)}
                  >
                    {!loading || subscribeStatus === null ? (
                      <Icon name="arrow" height="14" width="18" color="black" />
                    ) : (
                      <Icon
                        name={notifIcon}
                        height="14"
                        width="18"
                        color="black"
                      />
                    )}
                  </button>
                </form>
              </div>
              <div className="flex md:gap-16 xs:gap-y-12 xs:flex-wrap">
                {linksArray.map((item) => (
                  <div
                    className={`font-ubuntu font-normal leading-snug text-accent-dark xs:max-md:basis-1/2 flex w-max flex-col gap-4`}
                    key={item.title}
                  >
                    <p className="text-neutral-400">{item.title}</p>
                    {item.links.map(({ name, url, target }) => (
                      <a href={url} key={name} target={target}>
                        {name}
                      </a>
                    ))}
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-center">
              {/* <FooterLogo /> */}
              <FooterWordLogo />
            </div>

            <div className="flex flex-col justify-between md:gap-16 md:flex-row md:items-end">
              <div className="flex flex-row gap-6">
                <a href="https://twitter.com/swisstronik" target="_blank">
                  <SocialLinks.twitterIcon />
                </a>
                <a
                  href="https://www.linkedin.com/company/swisstronik/"
                  target="_blank"
                >
                  <SocialLinks.linkedinIcon />
                </a>
                <a href="https://link.swisstronik.com/a46c36" target="_blank">
                  <SocialLinks.discordIcon />
                </a>
                <a href="https://t.me/swisstronik" target="_blank">
                  <SocialLinks.telegramIcon />
                </a>
                <a href="https://github.com/SigmaGmbH" target="_blank">
                  <SocialLinks.gitHubIcon />
                </a>
              </div>
              <p className="pt-5 font-ubuntu font-normal text-neutral-400 md:pt-10">
                ©2024 Sigma Assets GmbH - CHE397.017.336
              </p>
            </div>
          </footer>
        </div>
      </>
    );
  }
};

export default LandingFooter;
