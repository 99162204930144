import React from "react";
import Marquee from "react-fast-marquee";

import {slideImages} from "../assets/helpers";

interface MarqueeProps {
}

const MarqueeComponent: React.FC<MarqueeProps> = () => {

  let nftGallery = []

  for (let i = 1; i < 19; i++) {
    nftGallery.push(`https://beige-accessible-prawn-910.mypinata.cloud/ipfs/bafybeicgqv7ryoxtcebots6y5nwnssm66ss7mo5zdhwunprvviz6pofwuy/thumn_${i}.png`)
  }

  return (
    <Marquee >
      {nftGallery.map((url, index) => (
        <div className="m-[4px] md:m-3 overflow-visible" key={index} >
          <img
            src={url}
            alt={`Slide ${index + 1}`}
            className="md:w-[150px] xs:w-[96px] rounded-lg"

          />
        </div>
      ))}
    </Marquee>

  );
};

export default MarqueeComponent;
