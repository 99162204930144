import axios, { AxiosResponse } from 'axios'
import { SubmitHandler } from 'react-hook-form'
import { IconType } from '../components/Icon/types';

interface SuccessResponse<T> extends AxiosResponse {
  data: T;
}

interface IFormInput {
  email: string
}
interface IsignatureMail {
  signature: string
}
//TODO: add to env file
const domain = 'https://panel.swisstronik.com'
const stageDomain = 'https://stage.panel.swisstronik.com'

const hostDomain = () => {
  const host = window.location.hostname
  return host === 'swisstronik.com' ? domain : stageDomain
}

interface mailObject {
  email: string
}

export const EmailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const SuccessfulMessage = 'Successfully subscribed!';
export const UnsuccessfulMessage = 'Subscription was unsuccessful!';


export function sendEmailSubscription(email: mailObject): Promise<SuccessResponse<any>> {
  return axios.post(`${domain}/api/mail/email`, email)
}

export function sendSignatureSubscription(signature: string): Promise<SuccessResponse<any>> {
  return axios.get(`${domain}/api/mail/subscribe/${signature}/`)
}

function unsubscribeEmail(signature: string)
  : Promise<SuccessResponse<any>> {
  return axios.get(`${domain}/api/mail/unsubscribe/${signature}/`)
}

export function subscriptionResultHandler(result: AxiosResponse) {
  if (!result || result.status !== 200) {
    return false
  }
  return result
}

export const getNotifedIcon = (loading: boolean, subscribeStatus: boolean | null)
  : IconType | undefined => {
  let notySelectedIcon: IconType | undefined = undefined;
  if (loading)
    notySelectedIcon = 'loadingCircle'
  else if (subscribeStatus !== null)
    notySelectedIcon = subscribeStatus ? 'check' : 'close'
  return notySelectedIcon
}


//NOTE subscribe && unsubscribe 
export const onSubmit: SubmitHandler<IFormInput> = async (data, e) => {
  e?.preventDefault()
  if (!data.email) return
  try {
    const response = await sendEmailSubscription({ email: data.email })
    return subscriptionResultHandler(response);
  } catch (error) {
    return
  }
}

export const reSubscribe = async (signature: string | undefined) => {
  if (!signature) return
  try {
    const response = await sendSignatureSubscription(signature)
    return subscriptionResultHandler(response);
  } catch (error) {
    return
  }
}

export const onUnsubscribe = async (signature: string | undefined) => {
  if (signature) {
    try {
      const response = await unsubscribeEmail(signature)
      return subscriptionResultHandler(response);
    } catch (error) {
      return
    }
  }
}

// invitation request
export const invitationRequest = async (data: object) => {
  try {
    return await axios.post(`${hostDomain()}/api/mail/investment-request`, data)
  } catch (error) {
    return
  }
}

